import './RecruitmentSurvey.less'

import { Button } from 'antd'
import { navigate } from 'gatsby-plugin-react-intl'
import React, { useEffect, useReducer, useState } from 'react'

import SurveyRejected from '../../../../assets/images/survey-rejected.svg'
import SurveyUnavailable from '../../../../assets/images/survey-unavailable.svg'
import { useScopedIntl } from '../../../../hooks'
import {
  AccountType,
  BackendError,
  CandidateStatus,
  CustomisationData,
  DateFormat,
  Feature,
  FileAnswer,
  QuestionType,
  SaveDeleteAnswerHandlers,
  SurveyAnswer,
  SurveyBlock,
  UiSettings,
  checkRecruitmentSurvey,
  defaultMaxFileBytes,
  defaultStudyColor,
  deleteRecruitmentSurveyAnswer,
  deleteRecruitmentSurveyFileAnswer,
  fetchCustomisationData as fetchCustomisationDataRequest,
  fetchRecruitmentConditionalLogic as fetchRecruitmentConditionalLogicRequest,
  fetchRecruitmentFilesUrls,
  fetchRecruitmentSurvey as fetchRecruitmentSurveyRequest,
  saveRecruitmentSurveyAnswer,
  saveRecruitmentSurveyFileAnswer,
  setLastLoginType,
  verifyRecruitmentSurvey
} from '../../../../requests'
import { routes } from '../../../../routes'
import { getRecruitmentCodeAndEmailFromHash, isAnswerValueEmpty } from '../../../../utils'
import { DatacBox, DatacIcon, DatacLoading, DatacMessage, DatacTitle } from '../../../common'
import {
  Survey,
  SurveyContextConsumer,
  SurveyContextProvider,
  SurveyCounts,
  SurveyDataTypes,
  SurveyDetailsInfoItem,
  SurveySaveAnswerFn,
  SurveyThankYou,
  surveyDataInitialState,
  surveyDataReducer
} from '../../../shared/Survey'

const ExpiredRecruitmentPage: React.FC = () => {
  const intlRecruitment = useScopedIntl('recruitment.survey.expired')
  return (
    <div className="recruitment-survey-expired">
      <SurveyUnavailable />
      <p>{intlRecruitment('label')}</p>
      <p>{intlRecruitment('content')}</p>
    </div>
  )
}

const UnqualifiedRecruitmentPage: React.FC = () => {
  const intlUnqualified = useScopedIntl('recruitment.survey.unqualified')
  const intlRecruitment = useScopedIntl('recruitment.survey')
  return (
    <DatacBox className="recruitment-survey-unqualified">
      <SurveyRejected />
      <DatacTitle>{intlUnqualified('label')}</DatacTitle>
      <div>{intlUnqualified('content')}</div>
      <Button
        type="link"
        className="recruitment-survey-unqualified__button"
        onClick={() => navigate(routes.subjectDashboard)}
      >
        {intlRecruitment('go_to_dashboard')}
        <DatacIcon name="arrowRight" />
      </Button>
    </DatacBox>
  )
}

interface RecruitmentLastPageProps {
  token: string
  recruitmentId: string
  hasSurvey: boolean
  hasSchedule: boolean
}

const RecruitmentLastPage: React.FC<RecruitmentLastPageProps> = ({ token, recruitmentId, hasSurvey, hasSchedule }) => {
  const [isVerifying, setIsVerifying] = useState(false)
  const [candidateStatus, setCandidateStatus] = useState<CandidateStatus>(null)
  const intlRecruitment = useScopedIntl('recruitment.survey')

  useEffect(() => {
    setIsVerifying(true)
    verifyRecruitmentSurvey(
      { token },
      {
        onSuccess: status => {
          if (hasSchedule && status !== CandidateStatus.Disqualified && status !== CandidateStatus.Excluded) {
            navigate(routes.subjectBookAppointments(recruitmentId, hasSurvey))
          } else {
            setCandidateStatus(status)
            setIsVerifying(false)
          }
        },
        onRequestError: () => {
          DatacMessage.error(intlRecruitment('unknown_error.title'), intlRecruitment('unknown_error.content'))
        }
      }
    )
  }, [])

  return (
    <DatacLoading isLoading={isVerifying}>
      {candidateStatus === CandidateStatus.Disqualified ? (
        <UnqualifiedRecruitmentPage />
      ) : (
        <>
          <SurveyThankYou feature={Feature.Recruitment} />
          <div className="datac-survey-content__controls">
            <Button
              onClick={() => navigate(routes.subjectDashboard)}
              className="datac-survey-content__controls-last-button"
              type="primary"
              size="large"
            >
              {intlRecruitment('go_to_dashboard')}
            </Button>
          </div>
        </>
      )}
    </DatacLoading>
  )
}

export const RecruitmentSurvey: React.FC = () => {
  const intl = useScopedIntl('')
  const intlSurvey = useScopedIntl('survey')
  const [token, email] = getRecruitmentCodeAndEmailFromHash()
  const intlRecruitment = useScopedIntl('recruitment.survey')
  const [loading, setLoading] = useState(true)
  const [isExpired, setIsExpired] = useState(false)
  const [surveyData, surveyDataDispatch] = useReducer(surveyDataReducer, surveyDataInitialState)
  const [uiSettings, setUiSettings] = useState<UiSettings>(null)
  const [isLoadingCustomisation, setIsLoadingCustomisation] = useState(false)
  const [recruitmentId, setRecruitmentId] = useState<string>(null)
  const [hasSchedule, setHasSchedule] = useState(false)

  useEffect(() => {
    setLastLoginType(AccountType.Subject)
    fetchCustomisationData()
    checkRecruitmentSurvey(
      { token },
      {
        onSuccess: () => fetchRecruitmentSurvey(),
        onNotFound: () => navigate(routes.recruitmentSignup(token)),
        onExpired: () => {
          setIsExpired(true)
          setLoading(false)
        },
        onUnauthorized: () => navigate(routes.signIn(AccountType.Subject, token, email)),
        onRequestError: () => {
          DatacMessage.error(intlRecruitment('unknown_error.title'), intlRecruitment('unknown_error.content'))
        }
      }
    )
  }, [])

  const fetchRecruitmentSurvey = () => {
    setLoading(true)
    fetchRecruitmentSurveyRequest(
      { token },
      {
        onSuccess: ({ surveyData, recruitmentId, hasSchedule }) => {
          surveyDataDispatch({ type: SurveyDataTypes.SET, survey: surveyData })
          setRecruitmentId(recruitmentId)
          setHasSchedule(hasSchedule)
          fetchRecruitmentConditionalLogic()
        },
        onRequestError: () => {
          DatacMessage.error(intlRecruitment('unknown_error.title'), intlRecruitment('unknown_error.content'))
        }
      }
    )
  }

  const fetchRecruitmentConditionalLogic = () => {
    fetchRecruitmentConditionalLogicRequest(
      { token },
      {
        onSuccess: conditionalRules => {
          surveyDataDispatch({ type: SurveyDataTypes.SET_CONDITIONAL_RULES, conditionalRules })
          setLoading(false)
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const detailsBoxes = (surveyCounts: SurveyCounts, questionsConditionFiltered: SurveyBlock[]) => (
    <>
      <SurveyDetailsInfoItem
        icon="helpCircle"
        title={intlSurvey('details.information.questions')}
        value={questionsConditionFiltered.length}
        hideOnMobile
        color={uiSettings?.mainColor || defaultStudyColor}
      />
      <SurveyDetailsInfoItem
        icon="checkCircle"
        title={intlSurvey('details.information.responses')}
        value={surveyCounts.fulfilled}
        hideOnMobile
        color={uiSettings?.mainColor || defaultStudyColor}
      />
    </>
  )

  const saveAnswer: SurveySaveAnswerFn = (answer, question) => {
    return new Promise((resolve, reject) => {
      const handlers = {
        onSuccess: (response?: SurveyAnswer) => {
          if (response) {
            surveyDataDispatch({ type: SurveyDataTypes.UPDATE_ANSWER, answer: response })
            if (response.validationMessages.length) reject()
          }
          resolve()
        },
        onDeleteSuccess: () => {
          const answerId = surveyData.answers.find((a: SurveyAnswer) => a.questionId === question.id)?.id
          surveyDataDispatch({
            type: SurveyDataTypes.REMOVE_ANSWER,
            id: answerId
          })
          resolve()
        },
        onRequestError: (code: number) => {
          const questionPrefix =
            code && code === BackendError.EPRO_STATUS_PUBLISHED ? 'no_question_error' : 'save_error'

          DatacMessage.error(
            intlSurvey(`answer.${questionPrefix}.title`),
            intlSurvey(`answer.${questionPrefix}.content`)
          )
          reject()
        }
      }

      const uploadFileHandlers = {
        onFileSizeExceeded: () => {
          DatacMessage.warning(intl('file_upload.error.title'), intl('api.9002'))
          reject()
        },
        onWrongFileFormat: () => {
          DatacMessage.warning(intl('file_upload.error.title'), intl('api.9007'))
          reject()
        }
      }

      const commonParams = {
        questionId: question.id,
        token
      }

      const deleteFn = (handlers: SaveDeleteAnswerHandlers) => {
        if (question.type === QuestionType.File) {
          const currentAnswer = surveyData.answers.find(a => a.questionId === question.id)
          deleteRecruitmentSurveyFileAnswer({ fileId: currentAnswer.value[0].id }, handlers, {
            token
          })
        } else {
          deleteRecruitmentSurveyAnswer({ ...commonParams }, handlers)
        }
      }

      const saveFn = (handlers: SaveDeleteAnswerHandlers) =>
        question.type === QuestionType.File
          ? saveRecruitmentSurveyFileAnswer(
              { ...commonParams, fileId: (answer as FileAnswer[])?.[0].id },
              { ...handlers, ...uploadFileHandlers }
            )
          : saveRecruitmentSurveyAnswer({ ...commonParams, answer }, handlers)

      // File only: if we need to replace a file, first delete and then send new one
      if (question.type === QuestionType.File && surveyData.answers.find(a => a.questionId === question.id)?.value) {
        deleteFn({
          onSuccess: () =>
            isAnswerValueEmpty(question.type, answer)
              ? handlers.onDeleteSuccess()
              : saveFn({ onSuccess: handlers.onSuccess, onRequestError: handlers.onRequestError }),
          onRequestError: handlers.onRequestError
        })
        return
      }

      if (isAnswerValueEmpty(question.type, answer)) {
        deleteFn({ onSuccess: handlers.onDeleteSuccess, onRequestError: handlers.onRequestError })
      } else {
        saveFn({ onSuccess: handlers.onSuccess, onRequestError: handlers.onRequestError })
      }
    })
  }

  const fetchCustomisationData = () => {
    setIsLoadingCustomisation(true)
    fetchCustomisationDataRequest(true, {
      onSuccess: (data: CustomisationData) => {
        setUiSettings({
          studyId: '',
          logoUrl: data.logoUrl || '',
          mainColor: data.mainColor || defaultStudyColor,
          buttonColor: data.buttonColor || defaultStudyColor,
          maxFileBytes: defaultMaxFileBytes,
          dateFormat: DateFormat.CA
        })
        setIsLoadingCustomisation(false)
      },
      onRequestError: code => {
        DatacMessage.genericError(intl, code)
        setIsLoadingCustomisation(false)
      }
    })
  }

  return (
    <div className="subject-repository-survey">
      <DatacLoading isLoading={loading || isLoadingCustomisation}>
        {isExpired ? (
          <ExpiredRecruitmentPage />
        ) : (
          <SurveyContextProvider
            subjectId={surveyData?.subjectId}
            blocks={surveyData?.blocks}
            answers={surveyData?.answers}
            onFileUrlFetch={fetchRecruitmentFilesUrls()}
            titleLabel={intlSurvey('details.name_label')}
            token={null}
            uiSettings={uiSettings}
          >
            <SurveyContextConsumer>
              {({ surveyCounts, filteredQuestions }) => (
                <Survey
                  name=""
                  endPages={[
                    {
                      component: (
                        <RecruitmentLastPage
                          token={token}
                          recruitmentId={recruitmentId}
                          hasSurvey={!!surveyData?.blocks?.length}
                          hasSchedule={hasSchedule}
                        />
                      )
                    }
                  ]}
                  onAnswerSave={saveAnswer}
                  detailsBoxes={detailsBoxes(surveyCounts, filteredQuestions)}
                />
              )}
            </SurveyContextConsumer>
          </SurveyContextProvider>
        )}
      </DatacLoading>
    </div>
  )
}
